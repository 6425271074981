<template>
  <b-card>
    <b-modal id="upload-document-modal" ref="modal" no-close-on-backdrop title="Upload Resource"
      :ok-title="editData ? 'Update' : 'Save'" cancel-variant="outline-secondary" @ok="submitDocument">
      <form ref="form" @submit.stop.prevent="uploadDocument">
        <!-- Upload Document -->
        <b-row>
          <b-col cols="11" md="11" class="mb-md-0 mb-2">
            <b-row>
              <b-col>
                <b-form-group label="Document Number" label-for="form_number"
                  invalid-feedback="Document number is required" :state="formNumberState" label-cols-md="4">
                  <b-form-input v-model="form_number" placeholder="Document Number" :state="formNumberState" required />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Document Name" label-for="document_name" invalid-feedback="Document name is required"
                  :state="documentNameState" label-cols-md="4">
                  <b-form-input v-model="document_name" placeholder="Document Name" :state="documentNameState" required />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Select Department" label-for="select_department"
                  invalid-feedback="Deparment is required" :state="selectedDepartmemntState" label-cols-md="4">
                  <v-select v-model="selectedDepartments" label="name" taggable :options="departments"
                    :reduce="val => val.id" multiple />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Document Type" label-for="document_type" invalid-feedback="Document type is required"
                  :state="selectedDocumentTypesState" label-cols-md="4">
                  <v-select v-model="selectedDocumentTypes" label="name" taggable :options="filterDocumentTypes"
                    :reduce="val => val.id" />
                  <!-- <b-form-select
                  v-model="selectedDocumentTypes"
                  :options="filterDocumentTypes"
                  value-field="id"
                  text-field="name"
                  multiple :select-size="4"
                  required></b-form-select> -->
                  <!-- <div class="mt-3">
                    Selected: <strong>{{ selectedDocumentTypes }}</strong>
                  </div> -->
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Choose Document" label-for="document" invalid-feedback="Document  is required"
                  :state="documentState" label-cols-md="4" />
                <b-form-file v-model="document" :state="documentState"
                  drop-placeholder="MS Word , MS Excel, Ms Powerpoint and PDF are allowed"
                  accept=".doc, .docx, .pdf, .xlsx" @change="on_document_change" />
                <div class="MSFront">
                  *MS Word , MS Excel, Ms Powerpoint and PDF are allowed
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </form>
    </b-modal>
    <b-modal id="share-document-modal" ref="modal" no-close-on-backdrop title="Resource Access"
      cancel-variant="outline-secondary" size="lg" ok-title="Save" @ok="submitShareResource">
      <form ref="form" @submit.stop.prevent="shareResource">
        <!-- Resource Access -->
        <b-row>
          <b-col cols="11" md="11" class="mb-md-0 mb-2">
            <b-row>
              <b-col>
                <p>For Campus/Department/Designation wise Access:</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3">
                <b-form-group>
                  <v-select v-model="pcConfigData.campus_id" placeholder="Campus" label="short_name" :options="campus"
                    :multiple="true" :reduce="val => val.id" />
                </b-form-group>
              </b-col>
              <b-col cols="3">
                <b-form-group>
                  <v-select v-model="pcConfigData.dept_id" placeholder="Department" label="name" :options="departments"
                    :reduce="val => val.id" @input="getDesignationById" />
                </b-form-group>
              </b-col>
              <b-col cols="3">
                <b-form-group>
                  <v-select v-model="pcConfigData.desig_id" placeholder="Designation" label="name" :options="designations"
                    :reduce="val => val.id" :multiple="true" />
                </b-form-group>
              </b-col>
              <!-- Remove Button -->
              <b-col cols="2">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1"
                  @click.prevent="handleAccrssConfigFunction">
                  <feather-icon icon="PlusIcon" />
                </b-button>
              </b-col>
            </b-row>
            <vue-good-table :columns="modalColumns" :rows="pcConfigFinalData" :rtl="direction" :pagination-options="{
              enabled: true,
              perPage: pageLength
            }" :line-numbers="true" theme="my-theme">
              <template slot="table-row" slot-scope="props">
                <!-- Column: Action -->
                <span v-if="props.column.field === 'action'">
                  <span class="d-flex">
                    <b-button class="mr-1 px-1" variant="outline-warning" @click="editPcConfig(props.row)">Edit</b-button>
                    <b-button class="px-1" variant="outline-danger"
                      @click.prevent="deletePcConfig(props.row)">Remove</b-button>
                  </span>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap">
                      Showing 1 to
                    </span>
                    <b-form-select v-model="pageLength" :options="['10', '20', '30', '40', '50']" class="mx-1"
                      @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                    <span class="text-nowrap"> of {{ props.total }} entries </span>
                  </div>
                  <div>
                    <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
                      align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                      @input="(value) => props.pageChanged({ currentPage: value })">
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
            <b-row>
              <b-col cols="8">
                <b-form-group>
                  <label>For Individual Access:</label>
                  <v-select v-model="individual" label="employee_name" placeholder="Select Individual"
                    :options="individualList" :reduce="val => val.employee_id" multiple />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="8">
                <b-form-group label-for="is_expiry">
                  <b-form-checkbox v-model="isExpiryDate" name="is_expiry">
                    Set Expiry Date Time
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="isExpiryDate">
              <b-col cols="8">
                <b-form-group>
                  <label>Your document will be shared with the people selected above from </label>
                  <b-row>
                    <b-col>
                      <flat-pickr v-model="date_time_start_at" class="form-control" placeholder="start date time"
                        :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }" required />
                    </b-col>
                    <b-col cols="1">
                      <label for="to">To</label>
                    </b-col>
                    <b-col>
                      <flat-pickr v-model="date_time_end_at" placeholder="end date time" class="form-control"
                        :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }" required />
                    </b-col>
                  </b-row>
                  <b-row v-if="date_time_start_at > date_time_end_at">
                    <b-col>
                      <p class="text-danger">
                        End date must be max than start date
                      </p>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </form>

    </b-modal>
    <b-modal id="view-document-modal" no-close-on-backdrop title="Upload Resource" size="lg"
      cancel-variant="outline-secondary">
      <!-- Upload Document -->
      <b-row>
        <b-col cols="11" md="11" class="mb-md-0 mb-2">
          <b-row>
            <b-col>
              <embed :src="openDocument" width="100%" height="400" type="application/pdf">
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-between">
      <b-form-group>
        <b-button v-if="getaccessData.length > 0 && (admin_permission === 1 || accessArray.includes('insert'))"
          v-b-modal.upload-document-modal variant="outline-primary" @click="resetForm">
          Upload Document
        </b-button>
      </b-form-group>
      <b-form-group>
        <v-select v-model="departmentId.id" class="style-chooser" placeholder="Select Department" label="name"
          :options="departments" :reduce="val => val.id" @input="searchDepartmentFunc" />
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table :columns="columns" :rows="uploadDocuments" :rtl="direction" :pagination-options="{
      enabled: true,
      perPage: pageLength,
    }" theme="my-theme">
      <template slot="table-row" slot-scope="props">
        <!-- Column: Status -->
        <span v-if="props.column.field === 'categories'">
          <span class="d-flex">
            <div v-for="category in props.row.categories" :key="category">
              <span class="badge badge-secondary mr-1">{{ category.category_name }}</span>
            </div>

          </span>
        </span>
        <span v-else-if="props.column.field === 'status'">
          <span class="d-flex">
            <p v-if="props.row.status == 1">Active</p>
            <p v-else>Inactive</p>
          </span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span class="d-flex">
            <b-button
              v-if="(getaccessData.length > 0 && (admin_permission === 1 || accessArray.includes('update'))) || props.row.is_shared === 0"
              class="mr-1 px-1" variant="outline-primary" @click="documentActiveDeactive(props.row)">
              {{ props.row.status == 1 ? 'Deactivate' : 'Activate' }}
            </b-button>
            <b-button class="mr-1 px-1" variant="outline-secondary" @click="forDocument(props.row)">
              View
            </b-button>
            <b-button
              v-if="(getaccessData.length > 0 && (admin_permission === 1 || accessArray.includes('share'))) || props.row.is_shared === 0"
              v-b-modal.share-document-modal class="mr-1 px-1" variant="outline-success" @click="shareRow(props.row)">
              Share
            </b-button>
            <b-button
              v-if="(getaccessData.length > 0 && (admin_permission === 1 || accessArray.includes('update'))) || props.row.is_shared === 0"
              class="mr-1 px-1" variant="outline-warning" @click="editRow(props.row)">Edit</b-button>
            <b-button
              v-if="(getaccessData.length > 0 && (admin_permission === 1 || accessArray.includes('delete'))) || props.row.is_shared === 0"
              class="px-1" variant="outline-danger" @click="deleteDocument(props.row)">Delete</b-button>
          </span>
        </span>

        <!-- Column: Common -->
        <!-- <span v-else>
          {{ props.formattedRow[props.column.field].name }}
        </span> -->
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select v-model="pageLength" :options="['10', '20', '30', '40', '50']" class="mx-1" @input="(value) => props.perPageChanged({ currentPerPage: value })
              " />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
              align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card>
</template>
<script>
import {
  BCard,
  BPagination,
  BFormInput,
  BFormFile,
  // BLink,
  BFormGroup,
  BFormSelect,
  BFormCheckbox,
  BRow,
  BCol,
  VBModal,
  BButton,
  // BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
// import Multiselect from 'vue-multiselect'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-select/dist/vue-select.css'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'

export default {
  components: {
    BCard,
    VueGoodTable,
    BPagination,
    BFormInput,
    BFormFile,
    // BLink,
    BFormGroup,
    BFormSelect,
    BFormCheckbox,
    BRow,
    BCol,
    BButton,
    // Multiselect,
    flatPickr,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      isExpiryDate: false,
      form_number: '',
      document_name: '',
      resource_access: [
        // {
        //   id: 1,
        //   campusState: null,
        //   selectedCampus: [],
        //   selecteddepartments: [],
        //   selectedDesignations: [],
        // },
      ],
      individualState: null,
      individual: [],
      date_time_start_at: null,
      date_time_end_at: null,
      startDateState: null,
      endDateState: null,
      selectedDepartments: [],
      selectedDocumentTypes: [],

      document: null,
      formNumberState: null,
      documentNameState: null,
      documentState: null,
      selectedDepartmemntState: null,
      selectedDocumentTypesState: null,
      editData: false,
      editFormNumber: null,
      openDocument: null,
      columns: [
        {
          label: 'Document Number',
          field: 'form_number',
          filterOptions: { enabled: true },
        },
        {
          label: 'Document Name',
          field: 'document_name',
          filterOptions: { enabled: true },
        },
        {
          label: 'Document Type',
          field: 'doc_type',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Upload Date',
          field: 'created_at',
          filterOptions: { enabled: true },
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'yyyy-MM-dd H:i:s a..aaa',
        },
        {
          label: 'Uploaded By',
          field: 'uploaded_by',
          filterOptions: { enabled: true },
        },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            enabled: true,
            placeholder: 'Select Status',
            filterFn: this.statusTypeFilterFn,
            filterDropdownItems: [
              { text: 'Active', value: '1' },
              { text: 'Inactive', value: '0' },
            ],
          },
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      filterCategory: null,
      filterDocumentTypes: [],
      filterCategories: [],
      CategoriesForFilter: [],

      options: [
        'Please select some item',
        'This is First option',
        'Default Selected Option',
        'This is another option',
        'This one is disabled',
      ],
      campus: [],
      departments: [],
      designations: [],
      uploadDocuments: [],
      individualList: [],
      getaccessData: [],
      accessArray: [],
      // Query
      modalEditMode: false,
      paymentCycleData: {
        name: null,
        months: [],
        configs: [],
      },
      pcConfigData: {},
      pcConfigFinalData: [],
      temp_pcConfigFinalData: [],
      modalColumns: [
        {
          label: 'Campus',
          field: 'campusNames',
        },
        {
          label: 'Department',
          field: 'departmentNames',
        },
        {
          label: 'Designation',
          field: 'designationNames',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,

        },
      ],
      modalRows: [],
      searchTerm: '',

      paymentCycleSessionId: 29,
      paymentCycleCampuses: [],
      paymentCycleClasses: [],
      paymentCycleCampusOptions: [],
      paymentCycleClassOptions: [],
      paymentCycleMonths: [],
      stdFeeConfigSessionOptions: [
      ],
      departmentId: {},
      tempSettings: [],
      admin_permission: '',
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'pcConfigData.dept_id': function (newVal) {
      if (newVal === '' || newVal === null) {
        this.designations = []
        this.pcConfigData.desig_id = []
      }
    },
  },
  mounted() {
    this.getResourceCategories()
    this.getResourceDocumentType()
    this.getResourceDocumentUpload()
    this.getCampus()
    this.getDepartment()
    this.getEmployee()
    this.getAccess()
    this.getCategoriesForFilter()
  },
  methods: {

    getCampus() {
      const token = localStorage.getItem('accessToken');
      const header = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      this.$http.get(`${window.apiUrl}resource-sharing/get-campus`, header)
        .then(res => {
          this.campus = res.data.data
        })
    },

    getDepartment() {
      const token = localStorage.getItem('accessToken');
      const header = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      this.$http.get(`${window.apiUrl}resource-sharing/get-department`, header)
        .then(res => {
          this.departments = res.data.data
        })
    },

    getEmployee() {
      const token = localStorage.getItem('accessToken');
      const header = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      this.$http.get(`${window.apiUrl}resource-sharing/get-employee`, header)
        .then(res => {
          this.individualList = res.data.data
        })
    },

    getDesignationById(parentId) {
      const token = localStorage.getItem('accessToken');
      const header = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      this.designations = []
      if (!parentId) {
        // eslint-disable-next-line no-param-reassign
        parentId = this.pcConfigData.dept_id
      }
      this.$http.get(`${window.apiUrl}resource-sharing/get-designation-by-id/${parentId}`, header)
        .then(res => {
          // this.pcConfigData.desig_id = []
          this.designations = res.data.data
        })
    },

    shareRow(rowData) {
      this.resetForm()
      this.id = rowData.id
      if (rowData.resource_access == null) {
        //
      } else {
        const data = JSON.parse(rowData.resource_access)
        const individualData = data.individual.filter(value => value !== null)
        this.individual = individualData || []
        // this.individual = JSON.parse(rowData.resource_access).individual
        //   ? JSON.parse(rowData.resource_access).individual
        //   : []
        this.pcConfigFinalData = JSON.parse(rowData.resource_access).group
          ? JSON.parse(rowData.resource_access).group
          : []
        if (JSON.parse(rowData.resource_access).expiry_date.to != null) {
          this.isExpiryDate = true
          this.date_time_start_at = JSON.parse(rowData.resource_access).expiry_date.from
            ? JSON.parse(rowData.resource_access).expiry_date.from
            : null
          this.date_time_end_at = JSON.parse(rowData.resource_access).expiry_date.to
            ? JSON.parse(rowData.resource_access).expiry_date.to
            : null
        }
      }
    },

    // eslint-disable-next-line consistent-return
    handleAccrssConfigFunction() {
      let errorMessage = null
      if (!this.pcConfigData.campus_id || this.pcConfigData.campus_id === ''
        || this.pcConfigData.campus_id === null) {
        errorMessage = 'Please Select campus'
      }
      if (errorMessage) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorMessage,
            icon: 'XIcon',
            variant: 'danger',
          },
        })
        return 0
      }

      let campusNames = null
      if (this.pcConfigData.campus_id) {
        campusNames = this.campus
          .filter(campus => this.pcConfigData.campus_id.includes(campus.id))
          .map(cam => cam.name).join(', ')
      }

      // eslint-disable-next-line no-unused-vars
      let departmentNames = null
      if (this.pcConfigData.dept_id) {
        departmentNames = this.departments
          .filter(x => x.id === this.pcConfigData.dept_id)
          .map(cl => cl.name).join(', ')
      }

      // eslint-disable-next-line no-unused-vars
      let designationNames = null
      if (this.pcConfigData.desig_id) {
        designationNames = this.designations
          .filter(desig => this.pcConfigData.desig_id.includes(desig.id))
          .map(cl => cl.name).join(', ')
      }

      console.log({
        campus_id: this.pcConfigData.campus_id,
        dept_id: this.pcConfigData.dept_id,
        desig_id: this.pcConfigData.desig_id,
      })
      this.temp_pcConfigFinalData.push({
        campus_id: this.pcConfigData.campus_id,
        dept_id: this.pcConfigData.dept_id,
        desig_id: this.pcConfigData.desig_id,
      })
      this.pcConfigFinalData.push({
        campus_id: this.pcConfigData.campus_id,
        campusNames,
        dept_id: this.pcConfigData.dept_id,
        departmentNames,
        desig_id: this.pcConfigData.desig_id,
        designationNames,
      })

      this.pcConfigData = []
    },

    editPcConfig(rowData) {
      this.modalEditMode = true
      this.modalEditId = rowData.id
      if (rowData.dept_id === null || rowData.dept_id === '') {
        this.designations = []
      }
      const token = localStorage.getItem('accessToken');
      const header = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      this.$http.get(`${window.apiUrl}resource-sharing/get-designation-by-id/${rowData.dept_id}`, header)
        .then(res => {
          this.designations = res.data.data
        })
      this.pcConfigData = {
        campus_id: rowData.campus_id,
        dept_id: rowData.dept_id,
        desig_id: rowData.desig_id,
      }
      this.pcConfigFinalData.splice(rowData.originalIndex, 1)
    },

    deletePcConfig(rowData) {
      this.$swal({
        title: 'Are you sure to delete?',
        text: "You won't be able to revert this!",
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.pcConfigFinalData.splice(rowData.originalIndex, 1)
        }
      })
    },
    forDocument(rowData) {
      if (!rowData.document_link) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Document Link not found',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
        return
      }
      window.open(`${window.apiUrlForDocumentStorage}storage/${rowData.document_link}`, '_blank')
      // this.$http.get(`${window.apiUrl}resource-sharing/get-documents/${rowData.id}`)
      //   .then(res => {
      //     if (res.status) {
      //       if (res.data.status) {
      //         if (res.data.data.extension === 'docx' || res.data.data.extension === 'doc') {
      //           window.open(`${window.apiUrlForDocumentStorage}storage/${rowData.document_link}`, '_blank')
      //         } else {
      //           window.open(`${window.apiUrlForDocumentStorage}storage/${rowData.document_link}`, '_blank')
      //           // here pdf document will open in new tab
      //         }
      //       } else {
      //         this.$toast({
      //           component: ToastificationContent,
      //           props: {
      //             title: res.data.message,
      //             icon: 'XIcon',
      //             variant: 'danger',
      //           },
      //         })
      //       }
      //     } else {
      //       this.$toast({
      //         component: ToastificationContent,
      //         props: {
      //           title: res.message,
      //           icon: 'XIcon',
      //           variant: 'danger',
      //         },
      //       })
      //     }
      //   })
    },
    getAccess() {
      const token = localStorage.getItem('accessToken');
      const header = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      this.$http.get(`${window.apiUrl}resource-sharing/get-access`, header)
        .then(res => {
          this.getaccessData = res.data.data

          if (this.getaccessData.length > 0) {
            if (res.data.data[0]) {
              if (res.data.data[0].is_admin === 1) {
                this.admin_permission = 1
              } else if (res.data.data[0].action_access) {
                this.accessArray = res.data.data[0].action_access
              } else {
                this.accessArray = []
              }
            }
          } else {
            this.accessArray = []
          }
        })
    },
    advanceSearch() {

    },
    statusTypeFilterFn(data, filterString) {
      return data === Number(filterString)
    },

    getCategoriesForFilter() {
      const token = localStorage.getItem('accessToken');
      const header = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      this.$http.get(`${window.apiUrl}resource-sharing/categories-for-filter`, header)
        .then(res => {
          this.CategoriesForFilter = res.data.data
        })
    },
    // previous
    getResourceCategories() {
      const token = localStorage.getItem('accessToken');
      const header = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      this.$http.get(`${window.apiUrl}resource-sharing/get-resource-document-categories`, header)
        .then(res => {
          this.filterCategories = res.data.data
        })
    },
    getResourceDocumentType() {
      const token = localStorage.getItem('accessToken');
      const header = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      this.$http.get(`${window.apiUrl}resource-sharing/get-resource-document-document-types`, header)
        .then(res => {
          this.filterDocumentTypes = res.data.data
        })
    },
    getResourceDocumentUpload() {
      const token = localStorage.getItem('accessToken');
      const header = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      this.$http.get(`${window.apiUrl}resource-sharing/get-resource-documents-upload`, header)
        .then(res => {
          this.uploadDocuments = res.data.data
          this.tempSettings = res.data.data
          if (this.departmentId.id) {
            this.searchDepartmentFunc(this.departmentId.id)
          }
        })
    },

    checkFormValidity() {
      this.formNumberState = null
      this.documentNameState = null
      this.selectedDepartmemntState = null
      this.selectedDocumentTypesState = null
      this.documentState = null
      if (this.form_number == null || this.form_number === '') {
        this.formNumberState = false
      }
      if (this.document_name == null || this.document_name === '') {
        this.documentNameState = false
      }
      if (this.selectedDepartments.length < 1) {
        this.selectedDepartmemntState = false
      }
      if (this.selectedDocumentTypes.length < 1) {
        this.selectedDocumentTypesState = false
      }

      if (this.formNumberState !== false && this.documentNameState !== false && this.selectedDepartmemntState !== false
        && this.selectedDocumentTypesState !== false) {
        return true
      }
      return false
    },
    on_document_change(event) {
      const document = event.target.files[0]
      if (document.type === 'video/mp4') {
        //
      }
      const reader = new FileReader()
      reader.onload = e => {
        this.document = e.target.result
      }
      console.log(document)
      reader.readAsDataURL(document)
    },
    filterData() {
      if (this.filterCategory) {
        this.uploadDocuments = this.uploadDocuments.filter(
          e => e.categories.includes(this.filterCategory),
        )
      } else {
        //
      }
    },
    searchDepartmentFunc(val) {
      this.uploadDocuments = this.tempSettings
      if (val) {
        this.uploadDocuments = this.uploadDocuments.filter(
          e => e.department_ids.includes(val),
        )
      } else {
        //
      }
    },
    shareResource() {
      if (this.isExpiryDate === false) {
        this.date_time_start_at = null
        this.date_time_end_at = null
      }
      if (this.isExpiryDate && (this.date_time_start_at === null && this.date_time_end_at === null)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Minimum One date should be given. Other wise uncheck.',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
        return
      }
      // eslint-disable-next-line no-unused-vars
      const object = {
        group: this.pcConfigFinalData,
        individual: this.individual,
        expiry_date: {
          from: this.date_time_start_at,
          to: this.date_time_end_at,
        },
      }

      const token = localStorage.getItem('accessToken');
      const header = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      this.$http.put(`${window.apiUrl}resource-sharing/document-share-access/${this.id}`, object, header)
        .then(res => {
          if (res.data.status) {
            this.getResourceDocumentUpload()
            this.resetForm()
            // Hide the modal manually
            this.$nextTick(() => {
              this.$bvModal.hide('share-document-modal')
              this.$swal({
                icon: 'success',
                title: 'Updated!',
                text: 'Resource access successfully',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
    uploadDocument() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      const token = localStorage.getItem('accessToken');
      const header = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      this.$http.post(`${window.apiUrl}resource-sharing/add-documents-upload`, {
        form_number: this.form_number,
        document_name: this.document_name,
        selectedDepartments: this.selectedDepartments,
        selectedDocumentTypes: this.selectedDocumentTypes,
        document: this.document,
      }, header).then(res => {
        if (res.data.status) {
          this.getResourceDocumentUpload()
          this.resetForm()
          // Hide the modal manually
          this.$nextTick(() => {
            this.$bvModal.hide('upload-document-modal')
          })
          this.$swal({
            icon: 'success',
            title: 'Added!',
            text: 'Document Uploaded successfully',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    editUploadDocument() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      const token = localStorage.getItem('accessToken');
      const header = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      this.$http.put(`${window.apiUrl}resource-sharing/update-document-uploads/${this.id}`, {
        form_number: this.form_number,
        document_name: this.document_name,
        selectedDepartments: this.selectedDepartments,
        selectedDocumentTypes: this.selectedDocumentTypes,
        document: this.document,
      }, header)
        .then(res => {
          if (res.data.status) {
            this.getResourceDocumentUpload()
            this.resetForm()
            // Hide the modal manually
            this.$nextTick(() => {
              this.$bvModal.hide('upload-document-modal')
              this.$swal({
                icon: 'success',
                title: 'Updated!',
                text: 'Document updated successfully',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
    documentActiveDeactive(rowData) {
      this.$swal({
        title: 'Are you sure to change the record',
        // text: "You won't be able to revert this!",
        icon: 'danger',
        showCancelButton: true,
        confirmButtonText: 'Yes, Change it.',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const token = localStorage.getItem('accessToken');
          const header = {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
          this.$http.get(`${window.apiUrl}resource-sharing/active-deactive-document-setting/${rowData.id}`, header)
            .then(res => {
              if (res.data.status) {
                this.getResourceDocumentUpload()
                this.$swal({
                  icon: 'success',
                  title: 'Updated!',
                  text: 'Documents updated successfully',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: res.data.message,
                    icon: 'XIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(err => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: err.message,
                  icon: 'XIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    deleteDocument(rowData) {
      this.$swal({
        title: 'Are you sure to delete?',
        text: "You won't be able to revert this!",
        icon: 'danger',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const token = localStorage.getItem('accessToken');
          const header = {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
          this.$http.delete(`${window.apiUrl}resource-sharing/delete-documents/${rowData.id}`, header)
            .then(res => {
              if (res.data.status) {
                this.getResourceDocumentUpload()
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Documents Deleted successfully',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: res.data.message,
                    icon: 'XIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(err => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: err.message,
                  icon: 'XIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    submitShareResource(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      if (this.editData) {
        // this.editUploadDocument()
      } else {
        this.shareResource()
      }
    },
    submitDocument(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      if (this.editData) {
        this.editUploadDocument()
      } else {
        this.uploadDocument()
      }
    },
    editRow(rowData) {
      this.resetForm()
      console.log(rowData)
      this.editFormNumber = rowData.form_number
      this.form_number = rowData.form_number
      this.document_name = rowData.document_name
      this.selectedDepartments = rowData.department_ids
      this.selectedDocumentTypes = rowData.document_type_id
      this.document = rowData.document
      this.editData = true
      this.id = rowData.id
      this.$nextTick(() => {
        this.$bvModal.show('upload-document-modal')
      })
      console.log(this.selectedDepartments)
    },
    viewDocument(rowData) {
      if (rowData.document) {
        this.openDocument = rowData.document
        this.$nextTick(() => {
          const documentType = this.openDocument.split(';')[0].split('/')[1]
          if (documentType === 'pdf') {
            this.$bvModal.show('view-document-modal')
          }
        })
      }
    },
    resetForm() {
      this.editFormNumber = null
      this.editData = false
      this.form_number = ''
      this.document_name = ''
      this.category = null
      this.selectedDepartments = []
      this.selectedDocumentTypes = []
      this.document = null
      this.formNumberState = null
      this.documentNameState = null
      this.documentState = null
      this.individualState = null
      this.individual = null
      this.date_time_end_at = null
      this.date_time_start_at = null
      this.startDateState = null
      this.endDateState = null
      this.isExpiryDate = null
      this.filterCategory = null
      this.id = ''
      this.pcConfigFinalData = []
      this.temp_pcConfigFinalData = []
      this.resource_access = []
    },
  },
}
</script>
<style  src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.v-select.style-chooser.vs--single.vs--searchable {
  width: 200px;
}

.action-width {
  width: 300px;
}

.MSFront {
  font-size: 13px;
}
</style>
